import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import ravenOne from '../../assets/images/raven/raven-1.webp';
import ravenTwo from '../../assets/images/raven/raven-2.webp';
import ravenThreeA from '../../assets/images/raven/raven-3-a.webp';
import ravenThreeB from '../../assets/images/raven/raven-3-b.webp';
import ravenFourA from '../../assets/images/raven/raven-4-a.webp';
import ravenFourB from '../../assets/images/raven/raven-4-b.webp';
import ravenFourC from '../../assets/images/raven/raven-4-c.webp';
import ravenBanner from '../../assets/images/raven/raven-banner.webp';
import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Raven extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <img className="case-study-banner" src={ravenBanner} alt="banner" />
          </section>
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Collaboration Portals to Close More Deals, Faster
                </div>
                <div className="subtitle section-margin">
                  Connecting the agent community for enhanced info sharing & transparency
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#003644'}}> RAVEN</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Build a platform that brings together realtors, brokers and loan officers in
                      the US real estate industry to leverage network effects, improve conversions,
                      and close deals faster.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed the whole platform end-to-end, from iOS, Android and multiple web
                      applications to a microservices backend that provided matching and integration
                      with MLS.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>

                    <p className="desc">
                      A fast-growing network of leading real estate agents and brokerages spread
                      across the country, helping Raven take back market share from leading
                      competitors such as Zillow.
                    </p>
                  </div>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/hiduE-Zup-o"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://raven.re/"
                        style={{textDecoration: 'underline'}}
                        target="_blank" rel="noreferrer"
                      >
                        Raven
                      </a>{' '}
                      wanted to build a platform for real estate agents in the US that would allow
                      them to match their listings with buyers with or without having those
                      properties listed on the local multi listing services (MLS). The service would
                      allow realtors to gauge demand without having to disclose that a property is
                      on sale and earn double-side commissions by matching their own buyers with
                      sellers.
                    </p>
                    <br />
                    <p className="description">
                      The client was looking for a development partner who could build their
                      platform end-to-end. They wanted a solution that when built for one MLS could
                      then be extended to over 900 different MLS across the country.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100  " src={ravenOne} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Raven required Tintash to bring our own expertise and co-create the whole
                      platform in the best way possible. We stepped in to provide end-to-end
                      solutions covering the whole range of their use cases.
                    </p>
                    <br />
                    <p className="description">
                      Our agile development team comprised iOS and Android developers, web backend
                      and frontend engineers, a UAT engineer, and a project manager. This team
                      worked closely with the client’s US-based product team, including their CEO
                      and CTO, to continuously translate product requirements into a working
                      software.
                    </p>
                    <br />
                    <p className="description">
                      Tintash kickstarted development by evaluating all the initial product
                      requirements and locking in a scope of work with the Raven team. However, two
                      weeks into the project, the client started changing the designs and product
                      requirements. Our team worked closely with them to quickly adapt engineering
                      and incorporate all the incoming changes.
                    </p>
                    <br />
                    <p className="description">
                      Working in two-week sprints, our team started delivering shippable product
                      increments after the end of just the second sprint i.e. four weeks. This agile
                      development afforded the client  invaluable customer feedback every two weeks
                      based on which we were able to rapidly iterate  and make improvements to the
                      product.
                    </p>
                    <br />
                    <p className="description">
                      As an example, during the project’s Alpha phase, the product was heavily
                      focused on catering to the real estate agents. Upon receiving early customer
                      feedback, Raven’s product team quickly realized the need for making a
                      customer-segment pivot. Once there was recognition that their target audience
                      included brokerage firms, our development efforts were expanded to include
                      that segment’s needs as well.
                    </p>
                    <br />

                    <p className="description">
                      Similarly, further customer feedback validated the need for building an
                      end-consumer-facing dashboard of the product. Allowing buyers and sellers to
                      browse through the listings inventory and specify their requirements would
                      reduce matching time and help real estate agents and brokerage firms close
                      deals faster.
                    </p>
                    <br />
                    <p className="description">
                      Towards this end, our team built a frontend to match the fidelity of Raven’s
                      major competitors, Zillow and Remax. This alone brought in a whole new wave of
                      real estate agents to the platform and improved Raven’s prospects of
                      onboarding and partnering with brokerage firms.
                    </p>
                  </div>
                  <div
                    className="text-wrap-container col-12 p-3 d-flex section-margin"
                    style={{backgroundColor: '#F2F2F2'}}
                  >
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-center mb-0">
                        Consumer Facing Dashboard
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={ravenTwo} alt="raven" />
                    </div>
                  </div>
                  <div
                    className="text-wrap-container col-12 p-3 d-flex section-margin"
                    style={{backgroundColor: '#F2F2F2'}}
                  >
                    <div className="col-lg-5 p-0 d-flex justify-content-center order-2 order-lg-1">
                      <img className="wrap-image" src={ravenThreeA} alt="raven" />
                    </div>
                    <div className="col-lg-2 p-0 d-flex align-items-center order-1 order-lg-2">
                      <div className="image-description text-center mb-0">
                        Agent Facing Dashboards
                      </div>
                    </div>
                    <div className="col-lg-5 p-0 d-flex justify-content-center order-3 order-lg-3">
                      <img className="wrap-image" src={ravenThreeB} alt="raven" />
                    </div>
                  </div>

                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Once the initial product requirements were delivered and some degree of
                      product/market fit was achieved, we entered the product’s Beta phase. At that
                      point, the client was majorly focused on user adoption and reaching out to
                      brokerages to get their buy-in on the platform. During that phase, our team
                      worked on scaling the initial success with one MLS and few brokerages to
                      multiple MLS and many brokerages.
                    </p>
                    <br />
                    <p className="description">
                      We built features such as AWS Lambda functions for syncing MLS data, providing
                      a cost effective solution for keeping the state of all sellers up-to-date in
                      the system. By automating the process of onboarding a new MLS, we managed to
                      reduce the required engineering effort from a couple of weeks down to just a
                      few minutes.
                    </p>
                    <br />
                    <p className="description">
                      As a result of those efforts, any registered real estate agent in the US
                      simply needed to accept an invitation from Raven and all of their listed
                      properties were automatically populated on the platform. Plus, the system
                      generated matches with prospective buyers.
                    </p>
                    <br />
                    <p className="description">
                      We also opened up the platform to include other user bases — title
                      representatives and lenders — in a bid to get them to act as advocates and
                      help improve adoption rates. The strategy worked great overall and yielded the
                      desired results. By providing custom views to both title reps and lenders
                      around their agent network, we were able to further drive growth and adoption
                      in new geographical markets.
                    </p>
                    <br />
                    <p className="description">
                      Towards the end of the project, as we neared completion on the product front,
                      Raven targeted a budgetary reallocation from product to business development
                      to focus more on increasing the platform’s adoption. To help our client
                      achieve their strategic objectives and maximize their ROI, Tintash gradually
                      ramped down its team size over the next quarter and worked on solidifying the
                      platform so that it could function without any oversight.
                    </p>
                    <br />
                    <p className="description">
                      To this date, the platform runs without a single developer. The backend
                      codebase has 100% test coverage. Tintash continues to provide maintenance
                      support to the project in instances like new iOS version, policy changes by
                      AWS, or framework deprecations, along with minor polishing and bug fixing on a
                      need-only basis.
                    </p>
                    <br />
                    <p className="description">
                      Constant client communication and feedback was an integral part of our
                      development process and the project’s success. Throughout the three years the
                      project ran, our squad communicated with Raven’s product team on a daily basis
                      to ensure maximum overlap, conducted fortnightly sprint meetings and demos,
                      and held separate vision alignment meetings as market feedback poured in and
                      the project requirements evolved.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <img
                      className="w-100 sub-pic col-4"
                      src={ravenFourA}
                      alt="bed bath and beyond"
                      style={{aspectRatio: '2/3.5'}}
                    />

                    <img
                      className="w-100 sub-pic col-4"
                      src={ravenFourB}
                      alt="bed bath and beyond"
                      style={{aspectRatio: '2/3.5'}}
                    />
                    <img
                      className="w-100 sub-pic col-4"
                      src={ravenFourC}
                      alt="bed bath and beyond"
                      style={{aspectRatio: '2/3.5'}}
                    />
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      Tintash delivered an end-user focused web portal for customers to browse
                      through available real estate listings on the Raven platform. We also
                      developed an app, available on both iOS and Android, that came with an
                      agent/lender/title rep facing dashboard and allowed adding and updating buyer
                      and seller info, making matches, and taking those matches forward.
                    </p>
                    <br />
                    <p className="description">
                      The platform also included a custom search algorithm that allowed running up
                      to a 1,000 searches concurrently. That matchmaking algorithm gave real-time
                      results for any change in requirements, helping Raven instantly connect
                      thousands of buyers and sellers across the country.
                    </p>
                    <br />
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      We are thrilled with the product Tintash has delivered. They specialize in
                      mobile game development, so our project was quite a bit outside of their
                      vertical, and yet they took what little context they had about the United
                      States real estate market and quickly understood the problem we were trying to
                      solve. That was pretty amazing. Tintash handled the complexity of our project
                      with relative ease, which not all companies can do.
                    </p>
                    <p className="review-name mt-3">
                      CTO, Raven on{' '}
                      <a
                        href="https://clutch.co/profile/tintash?page=1#review-906645"
                        style={{textDecoration: 'underline'}}
                        target="_blank" rel="noreferrer"
                      >
                        Clutch
                      </a>
                    </p>
                    <br />
                    <br />
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Raven;
